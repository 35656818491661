import React, { useEffect, useState } from "react";
import StanderdConstants from "../../services/StanderdConstants";
import useUserId from "../../services/useUserId";
import { type } from "@testing-library/user-event/dist/type";
import Toast from "./Toast";
import { useToast } from "../../services/useToast";

export default function Subscription({
  Type,
  Id,
  SubscriptionPlanTypeId,
  SubscriptionAmount,
  SubscriptionStatus,
  IsSubscribed,
}) {
  const [loading, setLoading] = useState(false);
  const [subscribed, setSubscribed] = useState(IsSubscribed);
  const [error, setError] = useState(null);
  const [paymentId, setpaymentId] = useState(null);
  const {
    isVisible,
    message,
    type,
    showToast,
    pauseHideTimer,
    resumeHideTimer,
  } = useToast();

  const UserId = useUserId();

  useEffect(() => {
    if (SubscriptionStatus) {
      setSubscribed(true);
    }
  }, [SubscriptionStatus]);

  // Load Razorpay script dynamically
  const loadScript = (src) => {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => resolve(true);
      script.onerror = () => resolve(false);
      document.body.appendChild(script);
    });
  };

  useEffect(() => {
    loadScript("https://checkout.razorpay.com/v1/checkout.js");
  }, []);
  useState(() => {
    if (error) {
      showToast(error, "error");
    }
  }, [error]);
  // Fetch Razorpay order details from the server
  const getRazorPayOrder = async (data) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/Razorpay/create-order`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }
      );
      if (!response.ok) {
        throw new Error("Failed to create Razorpay order");
      }
      const result = await response.json();
      setpaymentId(result.paymentId);
      return result.order;
    } catch (err) {
      console.error("Error fetching Razorpay order:", err);
      setError("Unable to process payment. Please try again later.");
      return null;
    }
  };

  const handleSubscription = async () => {
    setLoading(true);
    setError(null);

    // if (!SubscriptionAmount) {
    //   setError('Invalid subscription amount');
    //   setLoading(false);
    //   return;
    // }

    if (
      !SubscriptionAmount ||
      SubscriptionAmount <= 0 ||
      Number.isNaN(SubscriptionAmount)
    ) {
      const confirmResponse = await fetch(
        `${process.env.REACT_APP_API_URL}/Subscription/Subscribe/`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            userId: UserId,
            Type,
            Id,
            SubscriptionPlanTypeId,
            SubscriptionAmount,
          }),
        }
      );

      if (!confirmResponse.ok) {
        throw new Error("Failed to confirm subscription");
      }

      const result = await confirmResponse.json();
      console.log("Subscription successful:", result);
      setSubscribed(true);
    }

    const orderData = {
      currency: "INR",
      amount: SubscriptionAmount,
      UserId,
      Type,
      Id,
    };

    const RazorPayOrder = await getRazorPayOrder(orderData);

    if (RazorPayOrder) {
      const options = {
        key: StanderdConstants.RazorpayKeys.KeyId, // Replace with your Razorpay KeyId
        currency: RazorPayOrder.currency,
        amount: RazorPayOrder.amount,
        name: "Subscription",
        description: "Subscription for Pro Plan",
        order_id: RazorPayOrder.id, // Order ID from the backend
        handler: async (response) => {
          console.log("Payment Successful:", response);

          // Confirm payment on the backend
          try {
            const confirmResponse = await fetch(
              `${process.env.REACT_APP_API_URL}/Razorpay/Subscribe/`,
              {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify({
                  userId: UserId,
                  Type,
                  Id,
                  SubscriptionPlanTypeId,
                  SubscriptionAmount,
                  razorpay_paymentId: response.razorpay_payment_id,
                  razorpay_orderId: response.razorpay_order_id,
                  razorpay_signature: response.razorpay_signature,
                  paymentId,
                }),
              }
            );

            if (!confirmResponse.ok) {
              throw new Error("Failed to confirm subscription");
            }

            const result = await confirmResponse.json();
            console.log("Subscription successful:", result);
            setSubscribed(true);
          } catch (err) {
            console.error("Error confirming subscription:", err);
            setError(
              "Subscription confirmation failed. Please contact support."
            );
          }
        },
        prefill: {
          name: "Gaurav Kumar",
          email: "gaurav.kumar@yopmail.com",
          contact: "7976763442",
        },
        theme: {
          color: "#3399cc",
        },
      };

      const paymentObject = new window.Razorpay(options);
      paymentObject.open();
    } else {
      setError("Failed to initiate payment. Please try again.");
    }

    setLoading(false);
  };

  return (
    <div className="flex flex-row items-center">
      {/* Display error message */}
      {/* {error && <div className="text-red-500 mb-2">{error}</div>} */}

      {/* Subscription button */}
      {subscribed ? (
        <span className="lss-buttons text-sm  text-green-600  self-end">Subscribed</span>
      ) : (
        <button
          onClick={handleSubscription}
          className={`lss-buttons text-sm bg-sky-600 text-gray-50 
${loading ? "bg-gray-400 cursor-not-allowed" : ""}`}
          disabled={loading}
        >
          {loading ? "Processing..." : subscribed ? "Subscribed" : "Subscribe"}
        </button>
      )}

      <Toast
        message={message}
        isVisible={isVisible}
        type={type}
        pauseHideTimer={pauseHideTimer}
        resumeHideTimer={resumeHideTimer}
      />
    </div>
  );
}

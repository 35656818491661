import {
  IconButton,
  Dialog,
  DialogContent,
  Snackbar,
  Alert,
  Typography,
  TextField,
  Button,
  Box,
} from "@mui/material";
import XIcon from "@mui/icons-material/X";
import { Share, ContentCopy } from "@mui/icons-material";
import { useState } from "react";
import {
  FaWhatsapp,
  FaFacebookF,
  FaInstagram,
  FaTwitter,
  FaLinkedin,
} from "react-icons/fa";

import {
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
} from "react-share";

function ShareButton({ name, url }) {
  const [dialogOpen, setDialogOpen] = useState(false); // Dialog open state
  const [snackbarOpen, setSnackbarOpen] = useState(false); // Snackbar open state
  const [snackbarMessage, setSnackbarMessage] = useState(""); // Snackbar message

  const handleDialogOpen = () => {
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const shareToPlatform = (platform) => {
    const encodedMessage = encodeURIComponent(
      `Check out this content: ${name}\n${url}</a>`
    );
    let shareUrl = "";

    switch (platform) {
      case "whatsapp":
        shareUrl = `https://wa.me/?text=${encodedMessage}`;
        break;
      case "facebook":
        shareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
          url
        )}`;
        break;
      case "instagram":
        navigator.clipboard.writeText(`${name}\n${url}`);
        setSnackbarMessage(
          "Instagram does not support direct sharing of links. The link has been copied to your clipboard. Paste it into your Instagram bio or share it manually."
        );
        setSnackbarOpen(true);
        break;
      case "twitter":
        shareUrl = `https://twitter.com/intent/tweet?text=${encodedMessage}`;
        break;
      case "linkedin":
        shareUrl = `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(
          url
        )}`;
        break;
      default:
        break;
    }

    if (shareUrl) {
      window.open(shareUrl, "_blank");
    }

    handleDialogClose();
  };

  const handleCopyLink = () => {
    navigator.clipboard.writeText(url);
    setSnackbarMessage("Link copied to clipboard!");
    setSnackbarOpen(true);
  };

  return (
    <div className="relative mt-1">
      <Button onClick={handleDialogOpen} title="Share">
        <Share fontSize="small" /> Share
      </Button>

      <Dialog open={dialogOpen} onClose={handleDialogClose}>
        <DialogContent
          sx={{
            width: "550px", // Adjust the width of the popup
            textAlign: "center",
            overflow: "hidden",
          }}
        >
          {/* Typography Wrapper */}
          <Box
            sx={{
              backgroundColor: "#1976d2",
              color: "white",
              padding: "8px",
              marginBottom: "16px",
              marginLeft: "-24px",
              width: "550px",
              marginTop: "-20px",
            }}
          >
            <Typography variant="h6">Share this Content</Typography>
          </Box>

          <div className="flex justify-center space-x-6 mb-4">
            <IconButton
              onClick={() => shareToPlatform("whatsapp")}
              title="Share on WhatsApp"
            >
              <FaWhatsapp size="2rem" className="text-green-600" />
            </IconButton>
            <FacebookShareButton
              url={url}
              quote={`Take a look at this amazing content: ${name}`}
            >
              <FacebookIcon size={36} round={true} />
            </FacebookShareButton>
            <IconButton
              onClick={() => shareToPlatform("instagram")}
              title="Share on Instagram"
            >
              <FaInstagram size="2rem" className="text-purple-600" />
            </IconButton>
            <IconButton
              onClick={() => shareToPlatform("twitter")}
              title="Share on Twitter"
            >
              <FaTwitter size="2rem" className="text-blue-400" />
            </IconButton>
            <LinkedinShareButton
              url={url}
              quote={`Take a look at this amazing content: ${name}`}
            >
              <LinkedinIcon size={36} round={true} />
            </LinkedinShareButton>
          </div>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 1,
              width: "100%",
            }}
          >
            <TextField
              value={url}
              InputProps={{
                readOnly: true,
              }}
              fullWidth
              variant="outlined"
            />
            <Button
              variant="contained"
              startIcon={<ContentCopy />}
              onClick={handleCopyLink}
              sx={{
                backgroundColor: "#1976d2",
                color: "white",
                whiteSpace: "nowrap",
              }}
            >
              Copy Link
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={() => setSnackbarOpen(false)}
      >
        <Alert
          onClose={() => setSnackbarOpen(false)}
          severity="success"
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </div>
  );
}

export default ShareButton;

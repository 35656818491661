import { Button } from '@mui/material';
import React, { useEffect } from 'react';
import StanderdConstants from '../../services/StanderdConstants';

function Subscribe() {

  // This function gets the Razorpay order details and opens the Razorpay payment window.
  const getRazorpayOrderAndPay = async (amount, subscriptionName) => {
    const data = {
      currency: 'INR',
      amount: amount
    };

    // Function to fetch the Razorpay order from the backend
    const getRazorPayOrder = async (data) => {
      try {
        const res = await fetch(`${process.env.REACT_APP_API_URL}/Razorpay/create-order`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify( data )
        });

        if (res.ok) {
          const result = await res.json();
          return result;  // Returning order details from the response
        } else {
          console.error('Error creating Razorpay order');
          return false;
        }
      } catch (error) {
        console.error('Error fetching Razorpay order:', error);
        return false;
      }
    };

    // Fetch Razorpay order details asynchronously
    const RazorPayOrder = await getRazorPayOrder(data);
console.log(RazorPayOrder)
    if (RazorPayOrder) {
      const options = {
        key: StanderdConstants.RazorpayKeys.KeyId,  // Replace with your Razorpay KeyId
        currency: RazorPayOrder.currency,
        amount: RazorPayOrder.amount,
        name: subscriptionName,
        description: "Subscription for Pro Plan",
        order_id: RazorPayOrder.id,  // Order ID from the backend
        handler: function (response) {
          console.log('Payment Successful:', response);
          // Handle successful payment, e.g., send payment details to backend for verification
        },
        prefill: {
          name: "Gaurav Kumar",
          email: "gaurav.kumar@yopmail.com",
          contact: "7976763442",
        },
        theme: {
          color: "#3399cc",
        },
      };

      // Create the Razorpay payment object and open the payment window
      const paymentObject = new window.Razorpay(options);
      paymentObject.open();
    } else {
      console.error('Failed to fetch Razorpay order details');
    }
  };

  // Function to dynamically load Razorpay checkout script
  const loadScript = (src) => {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  };

  // Loading Razorpay script when the component mounts
  useEffect(() => {
    loadScript("https://checkout.razorpay.com/v1/checkout.js");
  }, []);

  return (
    <div>
      <Button onClick={() => { getRazorpayOrderAndPay(1, 'ProSubscription'); }}>
        Subscribe
      </Button>
    </div>
  );
}

export default Subscribe;

import React, { useContext, useEffect, useState } from "react";
import StanderdConstants from "../../services/StanderdConstants";
import { InputBox } from "../../ButtonsIconsInputs/CustomInputs";
import {
  EditIconButton,
  InviteButton,
  LikeButton,
} from "../../ButtonsIconsInputs/IconButtons";
import DeleteConfirmation from "../reusableComp/Deletecomfirmation";
import GenericSeriesCard from "../Channel/GenericSeriesCard";
import { SearchContext } from "../../useContext/SearchContext";
import useUserId from "../../services/useUserId";
import { useNavigate } from "react-router-dom";
import usePermissions from "../reusableComp/PermissionCheck";

function Series() {
  const [ChannelMasterData, setChannelMasterData] = useState([]);
  const [error, setError] = useState(null);
  const [filteredData, setfilteredData] = useState([]);
  const [query, setQuery] = useState("");
  const navigate = useNavigate();
  const { filterActionsByPermission, hasPermission } = usePermissions();
  const [Loading,setLoading]=useState(false)

  const { searchQuery, setSearchQuery } = useContext(SearchContext);
  const UserId = useUserId();
  const getContentData = async (data) => {
    setLoading(true)
    try {
      const result = await fetch(
        `${process.env.REACT_APP_API_URL}/Invitation/search-content`,
        {
          method: "post",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            searchType: "series",
            searchQuery: searchQuery.query,
            UserId,
          }),
        }
      );
      const response = await result.json();
      if (result.ok) {
        // Handle successful response
        console.log(response);
        setChannelMasterData(response.data?.series);
        setfilteredData(response.data?.series);
      }
      setLoading(false)
    } catch (error) {
      // Handle error
      setLoading(false)
      console.log(error);
    }
  };

  useEffect(() => {
    if (searchQuery) {
      getContentData();
    }
  }, [searchQuery]);

  useEffect(() => {
    getContentData();
  }, []);

  const handleSearch = (e) => {
    const searchQuery = e.target.value.toLowerCase();
    setQuery(searchQuery);
    if (!searchQuery) {
      setfilteredData(ChannelMasterData);
    } else {
      const result = ChannelMasterData.filter((row) =>
        Object.values(row) // Get all values from the object (row)
          .some(
            (value) =>
              value
                ?.toString()
                .toLowerCase()
                .includes(searchQuery.toLowerCase()) // Check if any value contains the search query
          )
      );
      setfilteredData(result);
    }
  };

  const MyActions = [
    {
      label: "Invite",
      handler: (item) => {
        navigate("/InviteCandidate", { state: { content: item } });
      },
      tooltip: "Invite",
      ButtonComponent: InviteButton,
      requiredPermission: "Assessment-Invite",
    },
  ];
  const AllowedActions = filterActionsByPermission(MyActions);

  return (
 Loading? (<div className="flex justify-center items-center min-h-screen text-center">...Loading</div>) :
   ( <div className="p-4">
      {
        <div>
          <div className="flex flex-row justify-start p-4 py-4  lg:w-1/5 md:2/5 ">
            {searchQuery.query && (
              <span className="flex flex-row font-medium text-gray-900 items-center whitespace-nowrap">
                {filteredData.length > 0
                  ? `Showing ${filteredData.length} result${
                      filteredData.length !== 1 ? "s" : ""
                    } for '${searchQuery.query}'`
                  : `No results found for '${searchQuery.query}'. Try different keywords.`}{" "}
              </span>
            )}
          </div>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-5">
            {filteredData.map((item, index) => {
              if (item.contentType == "series") {
                return (
                  <GenericSeriesCard
                    item={item}
                    ViewAssessmentOfSeriesData={true}
                    actions={AllowedActions}
                  />
                );
              }
            })}
          </div>
        </div>
      }
    </div>)
  );
}

export default Series;

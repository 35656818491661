import React, { useState, useEffect } from "react";
import { Button, Card, CardContent, Typography } from "@mui/material";
import StanderdConstants from "../../services/StanderdConstants";

function MySubscriptions() {
  const [currentPlan, setCurrentPlan] = useState("Free");
  const [plans, setPlans] = useState([
    { name: "Free", price: 0, description: "Access limited features", duration: "Lifetime" },
    { name: "Pro", price: 500, description: "Access all features", duration: "Monthly" },
    { name: "Enterprise", price: 5000, description: "Custom plan for businesses", duration: "Yearly" },
  ]);

  const loadScript = (src) => {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => resolve(true);
      script.onerror = () => resolve(false);
      document.body.appendChild(script);
    });
  };

  const handlePayment = async (plan) => {
    const isScriptLoaded = await loadScript("https://checkout.razorpay.com/v1/checkout.js");

    if (!isScriptLoaded) {
      alert("Failed to load Razorpay SDK");
      return;
    }

    const response = await fetch(`${process.env.REACT_APP_API_URL}/Razorpay/create-order`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ amount: plan.price * 100 }), // Convert to paise
    });

    const order = await response.json();

    const options = {
      key: StanderdConstants.RazorpayKeys.KeyId,
      currency: "INR",
      amount: order.amount,
      order_id: order.id,
      name: plan.name,
      description: plan.description,
      handler: function (response) {
        alert("Payment successful!");
        setCurrentPlan(plan.name);
      },
      prefill: {
        name: "virat kohli",
        email: "viratkohli@example.com",
        contact: "9999999999",
      },
      theme: {
        color: "#3399cc",
      },
    };

    const paymentObject = new window.Razorpay(options);
    paymentObject.open();
  };

  return (
    <div className="flex flex-col items-center space-y-4 my-4">
      <Typography variant="h5" className="mb-4">
        My Subscriptions
      </Typography>
      <div className="flex flex-row gap-5">
      {plans.map((plan) => (
        <Card
          key={plan.name}
          className={`w-80 border   ${currentPlan === plan.name ? "border-green-500" : "border-gray-300"} shadow-lg`}
        >
          <CardContent>
            <Typography variant="h6" className="font-bold">
              {plan.name}
            </Typography>
            <Typography variant="body1">{plan.description}</Typography>
            <Typography variant="body2" className="text-gray-600">
              Price: ₹{plan.price} / {plan.duration}
            </Typography>
            {currentPlan === plan.name ? (
              <Typography className="text-green-500 mt-2">Current Plan</Typography>
            ) : (
              <Button
                variant="contained"
                color="primary"
                className="mt-2"
                onClick={() => handlePayment(plan)}
              >
                Subscribe
              </Button>
            )}
          </CardContent>
        </Card>
      ))}
      </div>
    </div>
  );
}

export default MySubscriptions;

import React, { useState } from "react";
import {
  Chip,
  CircularProgress,
  IconButton,
  Menu,
  MenuItem,
  Snackbar,
  Alert,
} from "@mui/material";
import {
  AccountCircleRounded,
  MoreVert,
  Share,
  ThumbUp,
  ThumbUpOutlined,
} from "@mui/icons-material";
import Subscription from "../reusableComp/Subscription";
import StanderdConstants from "../../services/StanderdConstants";
import { Link } from "react-router-dom";
import "./App.css";
import altimage from "./Principles-of-assessment-scaled-1-1.webp";
import ShareButton from "./ShareButton";
function  GenericChannelCard({
  title,
  subtitle,
  imageSrc,
  badgeText,
  badgeColor,
  item,
  ShowSubscribeButton = true,
  actions = [], // Pass an array of actions
}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [imgLoaded, setImgLoaded] = useState(true);
  const [profileLoaded, setprofileLoaded] = useState(true);
  const [liked, setLiked] = useState(item.IsLiked);
  const [totalLikes, setTotalLikes] = useState(item.TotalLikes);
  const [likeLoading, setlikeLoading] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false); // Snackbar open state
  const [snackbarMessage, setSnackbarMessage] = useState(""); // Snackbar message

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  // console.log(item);

  const OnLike = async (ChannelId) => {
    setlikeLoading(true);
    try {
      const result = await fetch(
        `${process.env.REACT_APP_API_URL}/LikesCount/`,
        {
          method: "post",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            ContentId: ChannelId,
            ContentType: "channel",
            UserId: StanderdConstants.UserId,
          }),
        }
      );
      if (result.ok) {
        if (liked) {
          setTotalLikes(totalLikes - 1);
          setLiked(!liked);
        } else {
          setTotalLikes(totalLikes + 1);
        }
        setLiked(!liked);
        setlikeLoading(false);
      } else {
        setlikeLoading(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const keywordArray = item?.Keywords ? item.Keywords.split(",") : [];

  // Share function
 
  return (
    <div className="relative justify-self-center w-full rounded-lg overflow-hidden shadow-lg border transition-transform transform flex flex-col bg-white hover:shadow-2xl">
      {/* Badge */}
      {badgeText && (
        <div
          className={`absolute top-2 right-2 text-white text-xs font-semibold px-2 py-1 rounded-3xl ${badgeColor}`}
        >
          {badgeText}
        </div>
      )}

      {/* Image Container */}
      <div className="w-full h-48 bg-gray-200 flex items-center justify-center">
        {imgLoaded ? (
          <img
            className="w-full h-full object-cover"
            alt="thumbnail"
            src={`${process.env.REACT_APP_API_URL}/AssessmentImages/${item.ChannelLogo}`}
            onLoad={() => setImgLoaded(true)} // Set loaded state to true when image loads
            onError={() => setImgLoaded(false)} // Set loaded state to false if there's an error
          />
        ) : (
          <img
            className="w-full h-full object-cover"
            alt="thumbnail"
            src={altimage}
          />
        )}
      </div>

      {/* Content */}
      <div className="flex-grow ">
        <Link
          to={`/ChannelPreview/${item.ChannelId}`}
          state={{ ChannelId: item.channelId }}
        >
          <h2 className="font-bold text-lg mb-2 bg-sky-600 text-gray-50 truncate hover:cursor-pointer ">
            <span className="px-2 text-sm">{title}</span>
          </h2>
        </Link>
        <p className="px-2 flex flex-row gap-2  items-center">
          <div className="h-10 w-10  overflow-hidden flex items-center justify-center self-center rounded-full">
            {profileLoaded ? (
              <img
                src={`${process.env.REACT_APP_API_URL}${item.Profile}`}
                alt="profile"
                onLoad={() => setprofileLoaded(true)}
                onError={() => setprofileLoaded(false)}
              />
            ) : (
              <AccountCircleRounded
                className=" text-[2.5rem]"
                fontSize="2.5rem"
              />
            )}
          </div>
          <h1 className="self-center  font-medium">{item.CreaterName}</h1>
        </p>

        <p className="px-2 truncate whitespace-nowrap overflow-hidden my-2">
          {item.ChannelDescription}
        </p>
        <p className="px-2 truncate whitespace-nowrap overflow-hidden my-2">
         Price: {item?.SubscriptionPlanAmount || 0} {item?.CurrencyType}
        </p>
      </div>
      <div className="mx-1 flex flex-row gap-1  flex-wrap">
        {keywordArray.map((keyword, index) => (
          <span
            key={index}
            className="rounded-md bg-green-600 my-[2px] text-gray-50 text-sm mx-1 px-2"
          >
            <h1 className="max-w-[100px] truncate overflow-hidden text-sm text-ellipsis whitespace-nowrap">
              {keyword.trim()}
            </h1>
          </span>
        ))}
      </div>

      {/* Bottom Actions */}
      <span className="flex flex-row justify-between px-2 my-3 ">
        <div className="flex gap-2 flex-row">
          {ShowSubscribeButton && (
            <>
           
                <Subscription
                  Type="channel"
                  Id={item?.ChannelId}
                  SubscriptionAmount={item?.SubscriptionPlanAmount || 0}
                  SubscriptionPlanTypeId={item?.SubscriptionPlanTypeId}
                  IsSubscribed={item.IsSubscribed}
                  ButtonColor={badgeColor}
                />
           
            </>
          )}

          <div className="flex flex-row items-center">
            <button
              onClick={() => OnLike(item.ChannelId)}
              title="like"
              className="lss-buttons text-gray-800"
            >
              {!likeLoading ? (
                <span>
                  {liked ? (
                    <ThumbUp fontSize="small" />
                  ) : (
                    <ThumbUpOutlined fontSize="small" />
                  )}
                </span>
              ) : (
                <>
                  <CircularProgress size="1rem" />
                </>
              )}
              <span className="font font-medium text-sm">{totalLikes}</span>
            </button>
          </div>
          <ShareButton
            name={title}
            url={`${window.location.origin}/ChannelPreview/${item.ChannelId}`}
          />
        </div>

        {/* Dropdown for Extra Actions */}
        <div className="self-baseline rounded-md">
          <IconButton className="mt-2" onClick={handleClick}>
            <MoreVert />
          </IconButton>
          <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
            {actions.length > 0 ? (
              <div className="flex flex-col justify-center px-1">
                {actions.map((action, actionIndex) => {
                  const ButtonComponent = action.ButtonComponent;
                  return (
                    <ButtonComponent
                      key={actionIndex}
                      action={() => {
                        handleClose();
                        action.handler(item, actionIndex);
                      }}
                      tooltip={action.tooltip}
                      id={actionIndex}
                      itemName=""
                      itemData={item}
                      label={action.label}
                      className={action.className}
                    />
                  );
                })}
              </div>
            ) : (
              <MenuItem disabled>No Actions Available</MenuItem>
            )}
          </Menu>
        </div>
      </span>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={() => setSnackbarOpen(false)}
      >
        <Alert
          onClose={() => setSnackbarOpen(false)}
          severity="success"
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </div>
  );
}

export default GenericChannelCard;
